import React from 'react';
import './App.css';
import styled from 'styled-components';

// import Slider from './Carousel';

const Container = styled.div`
  width: 100%;
  height: 400px;
  @media (max-width: 600px) {
    height: 440px;
  }
  @media(max-width: 1024px) and  (min-width: 601px){
    height: 350px;
  }
`;

const SubItem = styled.div`
  width: 100%;
  height: 400px;
  display: inline-block;
  @media (max-width: 600px) {
    height: 440px
  }
`;

// const getAdvPlaces = () => {
//   const ids = [
//     // 'block_pad_id_579334241_579334239',
//     // 'block_pad_id_579334243_579334239',
//     // // 'block_pad_id_579334245_579334239',
//     // // 'block_pad_id_579336301_579334239',
//     // 'block_pad_id_579336303_579334239',
//     // 'block_pad_id_579336305_579334239',
//     // 'block_pad_id_579336307_579334239',
//     // 'block_pad_id_579336309_579334239',
//     // 'block_pad_id_579336311_579334239',
//     // 'block_pad_id_579336313_579334239',
//     'block_pad_id_579336315_579334239',
//     // 'block_pad_id_579336317_579334239',
//     // 'block_pad_id_579336319_579334239',
//     // // 'block_pad_id_579336321_579334239',
//     // 'block_pad_id_579336323_579334239',
//     // 'block_pad_id_579336325_579334239',
//     // 'block_pad_id_579336513_579334239',
//   ];
//   let result = [];
//   for (let i = 0; i < ids.length; i++) {
//     result.push({ component: SubItem, props: { id: ids[i] } });
//   }
//   return result;
// }


class App extends React.Component {

  componentDidMount() {
    var element = document.createElement('script');
    element.src = "https://ssp.rambler.ru/capirs_async.js";
    element.onerror = (err) => {
      // включить стандартный слайдер без ssp
      window.parent.postMessage("reset_carousel", "*");
    }

    if (document.body.append) {
      document.body.append(element);
    } else {
      document.body.appendChild(element);
    }

  }

  render() {
    return (
      <Container>
        {/* <Slider
          items={[...getAdvPlaces()]}
        /> */}
        <SubItem id="block_pad_id_579336315_579334239">

        </SubItem>
      </Container>
    );
  }
}
export default App;

